import CoverageIcon3 from "@/img/infographic_24.svg";
import CoverageIcon2 from "@/img/infographic_52.svg";
import CoverageIcon4 from "@/img/infographic_365.svg";
import CoverageIcon1 from "@/img/infographic_500.svg";
import WorldwideImagePath from "@/img/wordwide.png";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CoverageItem from "@cospex/client/parcel/components/CoverageItem";
import { Label } from "@cospex/client/parcel/components/Typography";
import { Box, Grid, Stack, Typography } from "@mui/material";

export default function Wordwide() {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
        }}
      >
        <Box flex="1 1 0">
          <Stack
            sx={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              p: 4,
            }}
          >
            <Label>{t("home-coverage-subtitle")}</Label>
            <Typography variant="h2" sx={{ mb: 6 }}>
              {t("home-coverage-title")}
            </Typography>
            <Box sx={{ maxWidth: 416, width: "100%" }}>
              <Grid container spacing={2}>
                <CoverageItem
                  icon={CoverageIcon1}
                  title={t("home-coverage-item-1-title")}
                />
                <CoverageItem
                  icon={CoverageIcon2}
                  title={t("home-coverage-item-2-title")}
                />
                <CoverageItem
                  icon={CoverageIcon3}
                  title={t("home-coverage-item-3-title")}
                />
                <CoverageItem
                  icon={CoverageIcon4}
                  title={t("home-coverage-item-4-title")}
                />
              </Grid>
            </Box>
          </Stack>
        </Box>
        <Stack
          flex="1 1 0"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box
            height="100%"
            width="100%"
            sx={{
              maxHeight: {
                xs: 300,
                md: "none",
              },
              objectFit: "cover",
            }}
            component="img"
            src={WorldwideImagePath}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
