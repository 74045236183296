import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  CardStepLabel,
  Section,
  SectionTitle,
} from "@cospex/client/parcel/components/Layout";
import { Box, Card, Grid, Typography, useTheme } from "@mui/material";

export default function HowItWorks() {
  const { t } = useTranslation();
  const theme = useTheme();

  const scaledAtDesktop = {
    transform: {
      md: "scale(.85)",
    },
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
      <Section>
        <SectionTitle>{t("home-how-it-works")}</SectionTitle>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md={4} display="flex" alignItems="stretch">
            <Card variant="outlined" sx={scaledAtDesktop}>
              <CardStepLabel>1</CardStepLabel>
              <Typography variant="h3" gutterBottom>
                {t("home-how-it-works-step-1-title")}
              </Typography>
              <Typography variant="body2">
                {t("home-how-it-works-step-1-description")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} display="flex" alignItems="stretch">
            <Card variant="outlined">
              <CardStepLabel>2</CardStepLabel>
              <Typography variant="h3" gutterBottom>
                {t("home-how-it-works-step-2-title")}
              </Typography>
              <Typography variant="body2">
                {t("home-how-it-works-step-2-description")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} display="flex" alignItems="stretch">
            <Card variant="outlined" sx={scaledAtDesktop}>
              <CardStepLabel>3</CardStepLabel>
              <Typography variant="h3" gutterBottom>
                {t("home-how-it-works-step-3-title")}
              </Typography>
              <Typography variant="body2">
                {t("home-how-it-works-step-3-description")}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </Box>
  );
}
