import FastImagePath from "@/img/fast.png";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { ParcelLocatorContainer } from "@cospex/client/parcel/components/Layout";
import ParcelLocator from "@cospex/client/parcel/components/ParcelLocator";
import { Label } from "@cospex/client/parcel/components/Typography";
import { Box, Stack, Typography } from "@mui/material";

type FastProps = {
  onTrack: () => void;
};
export default function Fast({ onTrack }: FastProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
        }}
      >
        <Box flex="1 1 0">
          <Box
            component="img"
            height="100%"
            width="100%"
            src={FastImagePath}
            sx={{
              maxHeight: {
                xs: 300,
                md: "none",
              },
              objectFit: "cover",
            }}
          />
        </Box>
        <Box flex="1 1 0">
          <Stack
            sx={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              p: 4,
            }}
          >
            <Label>{t("home-carrier-logos-subtitle")}</Label>
            <Typography variant="h2" gutterBottom>
              {t("home-carrier-logos-title")}
            </Typography>
            <Typography variant="body2" sx={{ pb: 3 }}>
              {t("home-carrier-logos-description")}
            </Typography>
            <ParcelLocatorContainer>
              <ParcelLocator onTrack={onTrack} />
            </ParcelLocatorContainer>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
