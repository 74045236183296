import Fast from "@/components/sections/Fast";
import HeroWithSearch from "@/components/sections/HeroWithSearch";
import HowItWorks from "@/components/sections/HowItWorks";
import Wordwide from "@/components/sections/Wordwide";
import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  ParcelLocatorContainer,
  Section,
  SectionSubtitle,
  SectionTitle,
} from "@cospex/client/parcel/components/Layout";
import ParcelLocator from "@cospex/client/parcel/components/ParcelLocator";
import PriceComponent from "@cospex/client/parcel/components/PriceComponent";
import { scrollToTop } from "@cospex/client/parcel/components/ScrollToTop";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { session } = useAuth();

  const onTrack = () => {
    if (session?.email) {
      navigate("/dashboard");
      return;
    }
    navigate("/payment");
  };

  return (
    <>
      <HeroWithSearch onTrack={onTrack} />
      <Section>
        <SectionSubtitle>{t("home-testimonials-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-testimonials-title")}</SectionTitle>
        <ReviewCarousel />
      </Section>
      <Fast onTrack={onTrack} />

      <Wordwide />
      <HowItWorks />

      <Section>
        <SectionSubtitle>{t("home-pricing-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-pricing-title")}</SectionTitle>
        <PriceComponent action={() => scrollToTop()} />
      </Section>

      <Section>
        <SectionTitle>{t("home-final-pitch-title")}</SectionTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ParcelLocatorContainer>
            <ParcelLocator onTrack={onTrack} />
          </ParcelLocatorContainer>
        </Box>
      </Section>
    </>
  );
}

export default Home;
