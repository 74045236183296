import HomeBackground from "@/img/home-background.png";
import Counter from "@cospex/client/components/Counter";
import FeaturesList from "@cospex/client/components/FeaturesList";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Section } from "@cospex/client/parcel/components/Layout";
import ParcelLocator from "@cospex/client/parcel/components/ParcelLocator";
import { Box, Grid, Typography, useTheme } from "@mui/material";

type HeroWithSearchProps = {
  onTrack: () => void;
};

export default function HeroWithSearch({ onTrack }: HeroWithSearchProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: "hidden",
        position: "relative",
        backgroundImage: { xs: "none", md: `url(${HomeBackground})` },
        backgroundSize: { sm: "82%", md: "78%", lg: "50%", xl: "38%" },
        backgroundRepeat: "no-repeat",
        backgroundPosition: {
          md: "top -2% right -120%",
          lg: "top 0 right -10%",
          xl: "top 0 right 16%",
        },
      }}
    >
      <Section>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: {
                  md: 5,
                  sm: 3,
                  xs: 1,
                },
              }}
            >
              <Typography variant="h1" gutterBottom>
                {t("home-title")}
                <span style={{ color: theme.palette.primary.main }}>
                  {" "}
                  {t("home-title-addendum")}
                </span>
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                {t("home-description")}
              </Typography>
              <ParcelLocator onTrack={onTrack} />
              <FeaturesList
                featureKeys={[t("home-features-1"), t("home-features-2")]}
              />
              <Counter>{t("parcels-tracked")}</Counter>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Section>
    </Box>
  );
}
